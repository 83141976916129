<template>
  <div class="company-list">
    <vm-table
      ref="vmTable"
      :filter.sync="filter"
      url="companies">
      <el-button slot="toolbar" type="primary" size="mini" @click="$refs.editPage.open(0)">{{$l('common.add','新增')}}</el-button>
      <template slot="adSearch">
        <div class="vm-search">
          <vm-search :label="$l('company.name','企业名称')">
            <el-input v-model.trim="filter.name" clearable></el-input>
          </vm-search>
          <vm-search :label="$l('company.creditCode','统一社会信用代码')">
            <el-input v-model.trim="filter.creditCode" clearable></el-input>
          </vm-search>
        </div>
      </template>
      <el-table-column align="center" type="index" width="50"></el-table-column>
      <el-table-column prop="name" :label="$l('company.name','企业名称')"></el-table-column>
      <el-table-column prop="shortName" label="企业简称" align="center"></el-table-column>
      <el-table-column prop="typeDesc" label="企业类型" align="center"></el-table-column>
      <el-table-column prop="creditCode" label="统一社会信用代码" align="center"></el-table-column>
      <el-table-column prop="contactName" label="联系人" align="center"></el-table-column>
      <el-table-column prop="contactPhone" label="联系电话" align="center"></el-table-column>
      <el-table-column :label="$l('common.function','操作')" align="center" class-name="vm-table_operate">
        <template slot-scope="scope">
          <el-button type="primary" @click="$refs.editPage.open(scope.row.id)">{{$l('common.edit','编辑')}}</el-button>
          <el-button type="danger" @click="deleteRow(scope.row)">{{$l('common.delete','删除')}}</el-button>
        </template>
      </el-table-column>
    </vm-table>
    <edit-page ref="editPage" @save-success="getList(-1)"></edit-page>
  </div>
</template>
<script>
  import EditPage from "./Edit.vue";
  export default {
    components: {EditPage},
    data() {
      return {
        filter: {
          name: "",
          creditCode: "",
        },
      };
    },
    mounted() {
      this.getList(1);
    },
    methods: {
      getList(pageNum) {
        this.$refs.vmTable.getList(pageNum);
      },
      deleteRow(row) {
        this.$confirm(`确定删除${row.companyName}吗?`, this.$l("common.tip", "提示"), {type: "warning"}).then(() => {
          this.$http.delete("companies", row.id).then(() => {
            this.getList(-1);
            this.$message.success(row.companyName + this.$l("common.deleteSuccessTip", "删除成功"));
          });
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
</style>